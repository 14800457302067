import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=locale]]/about": [64,[14]],
		"/(dex)/addresses/[id]": [15,[2]],
		"/(dex)/assistant": [~16,[2]],
		"/[[lang=locale]]/blog": [~65,[14]],
		"/[[lang=locale]]/blog/[slug]": [66,[14]],
		"/(dex)/codes-ai": [20,[2,7]],
		"/(dex)/codes-ai/[thread]": [21,[2,7]],
		"/(dex)/codes": [17,[2,3],[,4]],
		"/(dex)/codes/[chapter]": [18,[2,3,5],[,4,6]],
		"/(dex)/codes/[chapter]/[code]": [19,[2,3,5],[,4,6]],
		"/(dex)/company_numbers": [22,[2]],
		"/[[lang=locale]]/contact": [67,[14]],
		"/(dex)/contracts": [23,[2,8]],
		"/(dex)/contracts/invites": [26,[2,8]],
		"/(dex)/contracts/new_from_invite": [28,[2,8]],
		"/(dex)/contracts/new": [27,[2,8]],
		"/(dex)/contracts/signers": [29,[2,8]],
		"/(dex)/contracts/signers/[signer]": [30,[2,8]],
		"/(dex)/contracts/[id]": [24,[2,8]],
		"/(dex)/contracts/[id]/edit": [25,[2,8]],
		"/(dex)/declarations": [31,[2,9],[,10]],
		"/(dex)/declarations/approvals": [39,[2,9],[,10]],
		"/(dex)/declarations/edit": [40,[2,9],[,10]],
		"/(dex)/declarations/items": [41,[2,9],[,10]],
		"/(dex)/declarations/messages": [42,[2,9],[,10]],
		"/(dex)/declarations/new": [43,[2,9],[,10]],
		"/(dex)/declarations/[id]": [32,[2,9,11],[,10]],
		"/(dex)/declarations/[id]/audit": [33,[2,9,11],[,10]],
		"/(dex)/declarations/[id]/certificates": [34,[2,9,11],[,10]],
		"/(dex)/declarations/[id]/invoices": [35,[2,9,11],[,10]],
		"/(dex)/declarations/[id]/items/[item_id]": [36,[2,9,11],[,10]],
		"/(dex)/declarations/[id]/previous_documents": [37,[2,9,11],[,10]],
		"/(dex)/declarations/[id]/transport_equipment": [38,[2,9,11],[,10]],
		"/[[lang=locale]]/docs": [68,[14]],
		"/[[lang=locale]]/docs/[slug]": [69,[14]],
		"/[[lang=locale]]/duty-calc": [70,[14]],
		"/(dex)/email_preview": [44,[2]],
		"/[[lang=locale]]/hs-codes": [71,[14]],
		"/(dex)/invoices": [45,[2]],
		"/(dex)/invoices/[id]": [46,[2]],
		"/(dex)/login": [47,[2]],
		"/(dex)/mig/dms": [48,[2],[,12]],
		"/(dex)/organisations": [49,[2]],
		"/(dex)/organisations/[id]": [50,[2,13]],
		"/(dex)/organisations/[id]/addresses": [51,[2,13]],
		"/(dex)/organisations/[id]/declarations": [52,[2,13]],
		"/(dex)/organisations/[id]/emails": [53,[2,13]],
		"/(dex)/organisations/[id]/emails/[email_id]": [54,[2,13]],
		"/(dex)/organisations/[id]/exports": [55,[2,13]],
		"/(dex)/organisations/[id]/invoices": [56,[2,13]],
		"/(dex)/organisations/[id]/relations": [57,[2,13]],
		"/(dex)/organisations/[id]/relations/[relation_id]": [58,[2,13]],
		"/(dex)/organisations/[id]/users": [59,[2,13]],
		"/(dex)/organisations/[id]/users/[user_id]": [60,[2,13]],
		"/[[lang=locale]]/services": [72,[14]],
		"/[[lang=locale]]/services/[service]": [73,[14]],
		"/[[lang=locale]]/software": [74,[14]],
		"/(dex)/spec": [61,[2]],
		"/(dex)/trigger": [62,[2]],
		"/[[lang=locale]]": [63,[14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';